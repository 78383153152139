<template>
  <div>
    <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading" >
      <v-card-text>
        <div class="pb-2 d-flex align-center">
          <v-btn color="primary" elevation="0" small class="mr-2" v-if="isreviewer" @click="addeditflag=true;formdata={};uploaderror={};uploaderrormsg=''"><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn>
          <v-btn elevation="0" color="primary" small class="mr-2" v-if="isreviewer" @click="uploaddialog=true"><v-icon class="mr-1">mdi-upload</v-icon>Bulk Upload</v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
        </div>
        <DataTable
          :headers="tableHeaders"
          :items="formatedData"
          :enableslot="['action']"
          :loading="loading" >
          <template v-slot:action="{ item }">
            <div class="d-flex" >
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" @click="editVendor(item)" :disabled="!isreviewer">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Vendor Master</span>
            </v-tooltip>
           
            <v-tooltip bottom content-class="tooltip-bottom"  v-if="item.status">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="success" v-bind="attrs" v-on="on" @click="VendorStatus(item._id,'Disable')" :disabled="!isreviewer">
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </template>
              <span>Enable</span>
            </v-tooltip>
             <v-tooltip bottom content-class="tooltip-bottom" v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="error" v-bind="attrs" v-on="on" @click="VendorStatus(item._id,'Enable')" :disabled="!isreviewer">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </template>
              <span>Disable</span>
            </v-tooltip>
            </div>
            </template>
        </DataTable>
        <!-- <v-data-table dense
          :headers="headers"
          :items="listdata"
          :items-per-page="-1"
        >
        <template
          v-slot:item="{ item }"
        >
          <tr>
            <template v-for="(v,k) in headers">
              <td v-if="k!=='_id'"  :key="k">
                <span v-if="v.value === 'LDC Start date'">{{$nova.formatDate(item[v.value])}}</span>
                <span v-else-if="v.value === 'LDC End date'">{{$nova.formatDate(item[v.value])}}</span>
                <span v-else-if="v.value === 'LDC Threshold'">{{$nova.formatNumber((Number(item[v.value]) || 0).toFixed(0))}}</span>
                <span v-else-if="v.value === 'LDC Utilized YTD'">{{$nova.formatNumber((Number(item[v.value]) || 0).toFixed(0))}}</span>
                <span v-else-if="v.value === 'LDC Remaining balance'">{{$nova.formatNumber((Number(item[v.value]) || 0).toFixed(0))}}</span>
                <span v-else-if="v.value === 'Opening provision balance'">{{$nova.formatNumber((Number(item[v.value]) || 0).toFixed(0))}}</span>
                <span v-else-if="v.value === 'Opening Provision utilized YTD'">{{$nova.formatNumber((Number(item[v.value]) || 0).toFixed(0))}}</span>
                <span v-else-if="v.value === 'Opening Provision remaining balance'">{{$nova.formatNumber((Number(item[v.value]) || 0).toFixed(0))}}</span>
                <span v-else>{{item[v.value]}}</span>
              </td>
            </template>
          </tr>
        </template>
        </v-data-table> -->
      </v-card-text>
    </v-card>

    <!-- Bulk upload dialog -->
    <v-dialog v-model="uploaddialog" persistent>
      <v-card :loading="bulkuploadloading" loader-height="2" :disabled="bulkuploadloading">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Bulk Upload of Vendor Master</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="uploaddialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="pt-4 d-flex align-center mb-2">
          <lb-file v-model="file" :drag="false" :displayname="false" hidedetails accept=".csv,.xlsx,.xls" label=""/>
          <v-btn color="" small class="ml-2 shadow-off" text outlined @click="$nova.downloadFile(id, {}, '/v2/tds/vm/getbulkuploadtemplate')">Download Template</v-btn>
        </v-card-text>
        <v-card-text v-if="uploaderrormsg" class="pb-0">
          <v-alert type="error" text border="left" class="py-2">
            {{this.uploaderrormsg}}
          </v-alert>
        </v-card-text>
        <v-card-text v-if="uploaddatalist.length > 0">
          <div class="title"><b>{{uploaddatalist.length}} </b> rows found.</div>
          <div>Do you want to proceed to bulk upload?</div>
          <div class="red--text mb-2"><b>Note: </b>This will delete all the existing data from the system</div>
          <perfect-scrollbar style="max-height: 50vh">
            <div>
              <v-simple-table dense class="">
                <template v-slot:default>
                  <thead>
                    <th class=""></th>
                    <th class="" v-for="(v,k) in colnames" :key="k">{{v}}</th>
                  </thead>
                  <v-slide-y-transition class="" group tag="tbody">
                    <tr v-for="(v,k) in uploaddatalist" :key="k" class="">
                      <td class="text-center">
                        <v-tooltip v-if="v.stage === 2 && (v.approver || []).indexOf(userid) > -1" bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-exclaimation</v-icon>
                          </template>
                          <span>Approve</span>
                        </v-tooltip>
                      </td>
                      <td v-for="(hv, hk) in v" :key="hk">
                        <span v-if="hk==='LDC Start date' || hk==='LDC End date'">{{$nova.formatDate(hv)}}</span>
                        <span v-else-if="hk==='TDS Rate' || hk==='LDC Rate'">{{$nova.formatNumber(((Number(hv) || 0)*100), false, true, 2)}} %</span>
                        <span v-else-if="!isNaN(Number(hv))">{{$nova.formatNumber(hv)}}</span>
                        <span v-else>{{hv}}</span>
                      </td>
                    </tr>
                  </v-slide-y-transition>
                </template>
              </v-simple-table> 
            </div>
          </perfect-scrollbar>
          <div class="text-right pt-4">
            <v-btn color="primary" @click="bulkUpload()" elevation="0">Upload</v-btn>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>

    <!-- vmForm dialog -->
    <!-- <v-dialog v-model="addeditflag" persistent>
      <v-card width="1000">
        <v-card-title class="text-h5 grey lighten-2">
          Add data to Vendor Master
          <v-spacer></v-spacer>
          <v-btn icon class="ml-4" @click="addeditflag = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-4">
          <vmForm :data="{}" @vmdoneadd="vmUpdated('add')" @vmdoneedit="vmUpdated('edit')"></vmForm>
        </v-card-text>
      </v-card>
    </v-dialog> -->


     <lb-dialog v-model="addeditflag" :heading="formdata._id ?  'Update Vendor Master' : 'Create Vendor Master'" width="1000" :loading="vendorloading">
        <template v-slot:body>
        <v-row>
          <v-col cols="12">
            <lb-string v-model="formdata['Vendor Name']" label="Vendor Name" :error="uploaderror['Vendor Name']"/>
          </v-col>
          <v-col cols="4">
            <lb-string v-model="formdata['Vendor Code']" label="Vendor Code" :error="uploaderror['Vendor Code']"/>
          </v-col>
          <v-col cols="4">
            <lb-string v-model="formdata['PAN']" label="PAN" :error="uploaderror['PAN']"/>
          </v-col>
          <v-col cols="4">
            <lb-dropdown :items="['Goods','Service']" label="Goods/Services provider" v-model="formdata['Goods/Services provider']" :error="uploaderror['Goods/Services provider']"/>
          </v-col>
          <v-col cols="3">
            <lb-dropdown :items="['Yes','No']" label="LDC" v-model="formdata['LDC']" :error="uploaderror['LDC']"/>
          </v-col>
          <v-col cols="3">
            <lb-string v-model="formdata['LDC Number']" label="LDC Number" :error="uploaderror['LDC Number']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['LDC Rate']" label="LDC Rate" :error="uploaderror['LDC Rate']"/>
          </v-col>
          <v-col cols="3">
            <lb-date v-model="formdata['LDC Start date']" label="LDC Start Date" :error="uploaderror['LDC Start date']"/>
          </v-col>
          <v-col cols="3">
            <lb-date v-model="formdata['LDC End date']" label="LDC End Date" :error="uploaderror['LDC End date']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['LDC Remaining balance']" label="LDC Remaining balance" :error="uploaderror['LDC Remaining balance']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['LDC Threshold']" label="LDC Threshold" :error="uploaderror['LDC Threshold']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['LDC Utilized YTD']" label="LDC Utilized YTD" :error="uploaderror['LDC Utilized YTD']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['Opening provision rate']" label="Opening provision rate" :error="uploaderror['Opening provision rate']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['Opening provision balance']" label="Opening provision balance" :error="uploaderror['Opening provision balance']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['Opening Provision utilized YTD']" label="Opening Provision utilized YTD" :error="uploaderror['Opening Provision utilized YTD']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['Opening Provision remaining balance']" label="Opening Provision remaining balance" :error="uploaderror['Opening Provision remaining balance']"/>
          </v-col>
          <v-col cols="3">
            <lb-dropdown :items="['194J(a)','194J(b)','194A','194I(a)','194I(b)','194C','194Q','194H','195','206','194R','Equalisation Levy','N.A']" label="TDS Section" v-model="formdata['TDS Section']" :error="uploaderror['TDS Section']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['TDS Rate']" label="TDS Rate" :error="uploaderror['TDS Rate']"/>
          </v-col>
          <v-col cols="3">
            <lb-dropdown :items="['Yes','No']" label="Threshold" v-model="formdata['Threshold']" :error="uploaderror['Threshold']"/>
          </v-col>
          <v-col cols="3">
            <lb-number v-model="formdata['Threshold Amount']" label="Threshold Amount" :error="uploaderror['Threshold Amount']"/>
          </v-col>
          <v-col cols="6">
            <lb-dropdown :items="['Yes','No']" label="Specified person under 206AB/CCA" v-model="formdata['Specified person under 206AB/CCA']" :error="uploaderror['Specified person under 206AB/CCA']"/>
          </v-col>
          <v-col cols="6">
            <lb-number v-model="formdata['YTD Transactions']" label="YTD Transactions" :error="uploaderror['YTD Transactions']"/>
          </v-col>
        </v-row>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" v-if="formdata._id" @click="updateVendor()" >Update</v-btn>
          <v-btn small color="primary" v-else @click="saveVendor()" >Create</v-btn
          >
        </template>
      </lb-dialog>
  </div>
</template>

<script>
//import vmForm from './VendorMasterForm'
import DataTable from "../../../views/common/DataTable.vue";
// let xlsx = require('xlsx');

export default {
  name: 'tds_master_vendor',
  data: function(){
    return {
      pageprops: {
        name: "Vendor Master",
        breadcrumbs: [],
      },
      loading: false,
      listdata: [],
      addeditflag: false,
      bulkuploadloading: false,
      modifyindex: -1,
      colnames: ['Vendor Code','Vendor Name','PAN','Goods/Services provider','YTD Transactions','Threshold','Threshold Amount','TDS Section','TDS Rate','LDC','LDC Number','LDC Rate','LDC Threshold','LDC Utilized YTD','LDC Remaining balance','LDC Start date','LDC End date','Specified person under 206AB/CCA','Opening provision balance','Opening Provision utilized YTD','Opening Provision remaining balance','Opening provision section','Opening provision rate'],
      headerOne: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "vendorcode",
          text: "Vendor Code",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "vendorname",
          text: "Vendor Name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "pan",
          text: "PAN",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "provider",
          text: "Goods/Services provider",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "ytdtransactions",
          text: "YTD Transactions",
          sortable: true,
          // filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "threshold",
          text: "Threshold",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "thresholdamount",
          text: "Threshold Amount",
          sortable: true,
          datatype: "number",
          // filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "tdssection",
          text: "TDS Section",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "tdsrate",
          text: "TDS Rate",
          sortable: true,
          // filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "ldc",
          text: "LDC",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "ldcno",
          text: "LDC Number",
          sortable: true,
          // filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "ldcrate",
          text: "LDC Rate",
          sortable: true,
          // filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "ldcstartdate",
          text: "LDC Start Date",
          sortable: true,
          datatype: "date",
          lock: true,
          visible: false,
        },
        {
          value: "ldcenddate",
          text: "LDC End Date",
          sortable: true,
          datatype: "date",
          lock: true,
          visible: false,
        },
        {
          value: "ldcthreshold",
          text: "LDC Threshold",
          sortable: true,
          datatype: "number",
          // filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "ldcutilizedytd",
          text: "LDC Utilized YTD",
          sortable: true,
          datatype: "number",
          // filterable: true,
          lock: true,
          visible: false,
        },
         {
          value: "ldcbalance",
          text: "LDC Remaining balance",
          sortable: true,
          datatype: "number",
          // filterable: true,
          lock: true,
          visible: false,
        },
         {
          value: "specifiedpersonunder",
          text: "Specified person under 206AB/CCA",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
         {
          value: "openingprovisionbalance",
          text: "Opening provision balance",
          sortable: true,
          // filterable: true,
          datatype: "number",
          lock: true,
          visible: false,
        },
         {
          value: "openingprovisionutilizedyttd",
          text: "Opening Provision utilized YTD",
          sortable: true,
          // filterable: true,
          datatype: "number",
          lock: true,
          visible: false,
        },
         {
          value: "openingprovisionsection",
          text: "Opening provision section",
          sortable: true,
          // filterable: true,
          lock: true,
          visible: false,
        },
         {
          value: "openingprovisionrate",
          text: "Opening provision rate",
          sortable: true,
          // filterable: true,
          lock: true,
          visible: false,
        },
         {
          value: "openingprovisionremainingbalance",
          text: "Opening Provision remaining balance",
          sortable: true,
          // filterable: true,
          datatype: "number",
          lock: true,
          visible: false,
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      //  headers: [
      //   { text: 'Vendor Code', value: 'Vendor Code'},
      //   { text: 'Vendor Name', value: 'Vendor Name'},
      //   { text: 'PAN', value: 'PAN' },
      //   { text: 'Goods/Services provider', value: 'Goods/Services provider' },
      //   { text: 'YTD Transactions', value: 'YTD Transactions' },
      //   { text: 'Threshold', value: 'Threshold' },
      //   { text: 'Threshold Amount', value: 'Threshold Amount' },
      //   { text: 'TDS Section', value: 'TDS Section' },
      //   { text: 'TDS Rate', value: 'TDS Rate' },
      //   { text: 'LDC', value: 'LDC' },
      //   { text: 'LDC Number', value: 'LDC Number' },
      //   { text: 'LDC Rate', value: 'LDC Rate' },
      //   { text: 'LDC Start Date', value: 'LDC Start date' },
      //   { text: 'LDC End Date', value: 'LDC End date' },
      //   { text: 'LDC Threshold', value: 'LDC Threshold' },
      //   { text: 'LDC Utilized YTD', value: 'LDC Utilized YTD' },
      //   { text: 'LDC Remaining balance', value: 'LDC Remaining balance' },
      //   { text: 'Specified person under 206AB/CCA', value: 'Specified person under 206AB/CCA' },
      //   { text: 'Opening provision balance', value: 'Opening provision balance' },
      //   { text: 'Opening Provision utilized YTD', value: 'Opening Provision utilized YTD' },
      //   { text: 'Opening Provision Section', value: 'Opening provision section' },
      //   { text: 'Opening Provision Rate', value: 'Opening provision rate' },
      //   { text: 'Opening Provision remaining balance', value: 'Opening Provision remaining balance' },
      // ],
      uploaddialog: false,
      file: null,
      uploaddatalist: [],
      uploaderrordatalist: [],
      fileprocessing: {},
      vmerrorflag: false,
      requiredcolumns: ["Vendor Code","Vendor Name","PAN","Goods/Services provider","YTD Transactions","Threshold","Threshold Amount","TDS Section","TDS Rate","LDC","LDC Number","LDC Rate","LDC Threshold","LDC Utilized YTD","LDC Remaining balance","LDC Start date","LDC End date","Specified person under 206AB/CCA","Opening provision balance","Opening Provision utilized YTD","Opening Provision remaining balance","Opening provision section","Opening provision rate"],
      uploaderror: {},
      uploaderrormsg: "",
      formdata: {},
      formatedData:[],
      vendorloading:false,
      isreviewer: false,
    }
  },
  components: {
    //vmForm,
    DataTable
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  mounted() {
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.listdata.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1, 
          vendorcode:item['Vendor Code'],
          vendorname:item['Vendor Name'],
          pan:item['PAN'],
          provider:item['Goods/Services provider'],
          ldc:item['LDC'],
          ldcenddate:item['LDC End date'],
          ldcstartdate:item['LDC Start date'],
          ldcno:item['LDC Number'],
          ldcrate:item['LDC Rate'],
          ldcbalance:item['LDC Remaining balance'],
          ldcthreshold:item['LDC Threshold'],
          ldcutilizedytd:item['LDC Utilized YTD'],
          openingprovisionremainingbalance:item['Opening Provision remaining balance'],
          openingprovisionutilizedyttd:item['Opening Provision utilized YTD'],
          openingprovisionbalance:item['Opening provision balance'],
          openingprovisionrate:item['Opening provision rate'],
          openingprovisionsection:item['Opening provision section'],
          specifiedpersonunder:item['Specified person under 206AB/CCA'],
          tdsrate:item['TDS Rate'],
          tdssection:item['TDS Section'],
          threshold:item['Threshold'],
          thresholdamount:item['Threshold Amount'],
          ytdtransactions:item['YTD Transactions'],
          created_at: item.created_at,
          updated_at: item.updated_at,
          status:item.status,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    refreshData() {
      if(this.$nova.hasRight("tds_monthly", 'review')) this.isreviewer = true;
      else this.isreviewer = false;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.listdata = [];
      this.axios.post("/v2/tds/vm/get", {client: this.$store.state.client}).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.listdata = dt.data.data || [];
          this.formateData();
        }else{
          throw new "Error fetching data";
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    readVendorMaster(key){
      let file = this.file;
      if((file || {}).name && (this.fileprocessing[key] || 0) === 0){
        this.fileprocessing[key] = 1;
        this.bulkuploadloading = true;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx","xls","xlsm","xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if(JSON.stringify(dt[0] || []) === JSON.stringify(this.colnames)){
            let listdata = [];
            this.vmerrorflag = false;
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let idata = {};
                for (let i = 0; i < this.colnames.length; i++) {
                  const cel = this.colnames[i];
                  idata[cel] = el[i];
                }
                if(idata["LDC End date"] instanceof Date) idata["LDC End date"] = this.$nova.formatDateISO(new Date(idata["LDC End date"].getTime() + 86400000));
                if(idata["LDC Start date"] instanceof Date) idata["LDC Start date"] = this.$nova.formatDateISO(new Date(idata["LDC Start date"].getTime() + 86400000));
                listdata.push(idata);
              }
            }
            this.uploaddatalist = listdata;
            console.log(this.uploaddatalist);
            this.uploaderrordatalist = [];
          }else throw new Error("Columns "+this.colnames.join(',')+" are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.bulkuploadloading = false;
        });
      }
    },
    // generateTable() {
    //   if(typeof (this.file || {}).name === "string"){
    //     console.log("File name is:",this.file);
    //     let ext = this.file.name.split(".").pop();
    //     if(["xlsx", "xls", "csv"].indexOf(ext) > -1){
    //       if(ext !== "csv" && this.file.size > 1024*1024*2){
    //         this.file = null;
    //       }else{
    //         let f = this.file;
    //         let fmt = this.file.name.split('.').pop();
    //         let parent = this;
    //         let readCSVFile = new FileReader();
    //         let headerFile = new FileReader();
    //         let hf = null;
    //         readCSVFile.onload = function(e) {
    //           let d = e.target.result.split('\n');
    //           let hd = d
    //           hd = hd.join("\n");
    //           hf = new File([hd], 'tfile', {type:'text/csv'});
    //           headerFile.readAsBinaryString(hf);
    //         }
    //         readCSVFile.onerror = function(ex) {console.log(ex);parent.uploaddatalist = []};
    //         headerFile.onload = function(e) {
    //           let d = e.target.result;
    //           let wb = xlsx.read(d, {type: 'binary', cellDates: true, raw: true}); 
    //           let sh = wb.SheetNames[0];
    //           let dt = xlsx.utils.sheet_to_json(wb.Sheets[sh], {header: 1, raw: true});
    //           parent.uploaddatalist = dt;
    //           // For checks
    //           let missingcols = [];
    //           if(parent.uploaddatalist.length > 0){
    //             if(parent.uploaddatalist[0] instanceof Array){
    //               for (const i of parent.requiredcolumns) {
    //                  if(parent.uploaddatalist[0].indexOf(i) === -1) missingcols.push(i);
    //               }
    //             }
    //           }
    //           if(missingcols.length > 1) parent.uploaderror = "Columns '"+missingcols.join(", ")+"' are missing.";
    //           else if(missingcols.length > 0) parent.uploaderror = "Column '"+missingcols.join(", ")+"' is missing.";
    //         }
    //         headerFile.onerror = function(ex) {console.log(ex);parent.uploaddatalist = []};
    //         if(fmt == "csv") readCSVFile.readAsText(f);
    //         else headerFile.readAsBinaryString(f);
    //       }
    //     }else this.uploaddatalist = [];
    //   }else {
    //     this.uploaddatalist = [];
    //   }
    // },
    bulkUpload() {
      this.bulkuploadloading = true;
      this.axios.post("/v2/tds/vm/bulkupload", {data: this.uploaddatalist}).then(dt => {
        if(dt.data.status === "success"){
          this.uploaddialog = false;
          this.file=null;
          this.refreshData();
        }else{
          this.uploaderrormsg = dt.data.data || dt.data.message || "Error uploading data";
          if (dt.data.data.length > 0) {
            if (dt.data.data[0]?.index0) this.uploaderror = dt.data.data[0].index0;
          }
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.bulkuploadloading = false;
      })
    },
    vmUpdated: function(t){
      this.addeditflag = false;
      this.refreshData();
      if(t === "add") this.$store.commit("sbSuccess", "Vendor detail added");
      else this.$store.commit("sbSuccess", "Vendor detail updated");
    },
    saveVendor() {
      this.vendorloading = true;
      const url = "/v2/tds/vm/add";
      const requestData = { data: [this.formdata]};
      return this.axios.post(url, requestData)
        .then(dt => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Vendor detail added");
            this.refreshData();
            this.addeditflag = false;
          } else {
            this.uploaderror = dt.data.data.length > 0 ? dt.data.data[0].index0 : {};
            throw new Error(dt.data.message || "Error uploading data");
          }
        })
        .catch(err => {
          console.log(err);
          this.$store.commit("sbError", err.message || err);
        })
        .finally(() => {
          this.vendorloading = false;
        });
    },
   editVendor(item) {
    this.uploaderror = {};
    this.addeditflag = true;
    this.formdata = {
      _id: item._id,
      "Vendor Code": item.vendorcode,
      "Vendor Name": item.vendorname,
      "PAN": item.pan,
      "Goods/Services provider": item.provider,
      "LDC": item.ldc,
      "LDC End date": item.ldcenddate,
      "LDC Start date": item.ldcstartdate,
      "LDC Number": item.ldcno,
      "LDC Rate": item.ldcrate,
      "LDC Remaining balance": item.ldcbalance,
      "LDC Threshold": item.ldcthreshold,
      "LDC Utilized YTD": item.ldcutilizedytd,
      "Opening Provision remaining balance": item.openingprovisionremainingbalance,
      "Opening Provision utilized YTD": item.openingprovisionutilizedyttd,
      "Opening provision balance": item.openingprovisionbalance,
      "Opening provision rate": item.openingprovisionrate,
      "Opening provision section": item.openingprovisionsection,
      "Specified person under 206AB/CCA": item.specifiedpersonunder,
      "TDS Rate": item.tdsrate,
      "TDS Section": item.tdssection,
      "Threshold": item.threshold,
      "Threshold Amount": item.thresholdamount,
      "YTD Transactions": item.ytdtransactions
      };
    },
    updateVendor() {
    this.vendorloading = true;
    const url = "/v2/tds/vm/edit/" + this.formdata._id;
    const requestData = { data: [this.formdata] };
   return this.axios.post(url, requestData)
    .then(dt => {
      if (dt.data.status === "success") {
        this.$store.commit("sbSuccess", "Vendor detail updated");
        this.refreshData();
        this.addeditflag = false;
      } else {
        this.uploaderror = dt.data.data.length > 0 ? dt.data.data[0].index0 : {};
        throw new Error(dt.data.message || "Error uploading data");
      }
    })
    .catch(err => {
      console.log(err);
      this.$store.commit("sbError", err.message || err);
    })
    .finally(() => {
      this.vendorloading = false;
    });
},
    VendorStatus(id,status){
      let link = '';
      if (status === 'Enable') { link = '/v2/tds/vm/enable/'+id; } else { link = '/v2/tds/vm/disable/'+id; }
      this.loading = true;
      this.axios.post(link, {data: [this.formdata]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Vendor Detail " + status + ' Successfully');
          this.refreshData();
        }else{
          throw new Error (dt.data.message || "Error " + status + " data");
        }
      }).catch(err => {
        console.log(err);
        this.$store.commit("sbError", err.message || err);
      }).finally(() => {
         this.loading = false;
      })
    }
  },
  computed: {
    tableHeaders() {
      // Generate headers based on isreviewer value
      if (this.isreviewer) {
        return this.headerOne;
      } else {
        // Return headers excluding the 'Action' header
        return this.headerOne.filter(header => header.value !== 'action');
      }
    }
  },
  watch: {
    'file': function(){
      this.uploaderror = {};
      this.uploaderrormsg = "";
      this.readVendorMaster();
    },
    "$store.state.client": function(){
      this.listdata = [];
      this.refreshData();
    }
  }
}
</script>
